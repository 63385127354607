import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'

// Store
import { actions } from 'core/store'

// Styled Elements
import {
  Text,
  Label,
  Title,
  SubTitle,
  TableColWrapper,
  TableColContainer,
  TableRowWrapper,
  TableRowContainer,
  TableButtonWrapper,
} from './VaspNotificationTableItem.elements'

// Views
import { Button, CardTableItem } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions
function VaspNotificationTableItem(props) {
  // Destructure
  const { vaspDetails, actions } = props
  const { external_id, name_legal, id, email, website, created } = vaspDetails

  // Actions
  const { toggleNotifyVaspModal, setNotifyVaspDetails } = actions
  // State
  const [cardName, setCardName] = useState({})

  // Functions
  const handleNotifyVasp = () => {
    setNotifyVaspDetails({ vasp_id: id, vasp_name: name_legal })
    toggleNotifyVaspModal()
  }

  // useEffect
  useEffect(() => {
    if (name_legal) {
      const nameArr = name_legal.split(' ')
      const firstNameLength = Math.floor(nameArr.length / 2)
      const firstName = []
      for (let i = 0; i < firstNameLength; i++) {
        firstName.push(nameArr[i])
      }
      nameArr.splice(0, firstNameLength)
      setCardName({
        initials: name_legal[0] + name_legal[1],
        fullname: [firstName.join(' '), nameArr.join(' ')],
      })
    }
  }, [external_id])

  return (
    <CardTableItem key={external_id} style={{ cursor: 'default' }}>
      <NavLink to={`/admin/details/vaspnotification/${id}`}>
        <TableRowWrapper>
          <Title>{cardName.initials || ''}</Title>
          <TableColContainer>
            <SubTitle>{cardName.fullname && cardName.fullname[0]}</SubTitle>
            <SubTitle>{cardName.fullname && cardName.fullname[1]}</SubTitle>
          </TableColContainer>
        </TableRowWrapper>
      </NavLink>

      <TableColWrapper>
        <TableRowContainer>
          <Label>Website: </Label>
          <Text>{website || 'NA'}</Text>
        </TableRowContainer>

        <TableRowContainer>
          <Label>Email: </Label>
          <Text>{email || 'NA'}</Text>
        </TableRowContainer>

        <TableRowContainer>
          <Label>Latest update: </Label>
          <Text>{moment(created).format('MMMM DD YYYY, h:mm:ss')}</Text>
        </TableRowContainer>
      </TableColWrapper>
      <TableButtonWrapper>
        <Button variant="outlined" onClick={handleNotifyVasp}>
          Notify
        </Button>
      </TableButtonWrapper>
    </CardTableItem>
  )
}

// Default Props
VaspNotificationTableItem.defaultProps = {}

// Proptypes Validation
VaspNotificationTableItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  vaspDetails: PropTypes.any,
  actions: PropTypes.shape({
    toggleNotifyVaspModal: PropTypes.func,
    setNotifyVaspDetails: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(VaspNotificationTableItem)
