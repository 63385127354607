// Assets
import Bitcoin from 'assets/images/blockChainLogos/bitcoin.png'
import BitcoinCash from 'assets/images/blockChainLogos/bitcoinCash.png'
import Ethereum from 'assets/images/blockChainLogos/ethereum.png'
import Litecoin from 'assets/images/blockChainLogos/litecoin.png'

// DIRECTORY
export const ACCOUNT_DETAILS_PAGES = [
  { value: 0, label: 'Overview' },
  // { value: 1, label: 'Activity' },
]
export const ACCOUNT_DETAILS_OVERVIEW_PAGES = [
  { value: 0, label: 'Addresses' },
  { value: 1, label: 'Account Details' },
]
export const ACCOUNT_DETAILS_ACTIVITY_PAGES = [
  // { value: 0, label: 'Activity' },
  { value: 0, label: 'Transactions' },
  { value: 1, label: 'Travel Rule' },
  { value: 2, label: 'Alerts' },
]
export const RECIPIENT_DETAILS_PAGES = [
  { value: 0, label: 'Overview' },
  // { value: 1, label: 'Activity' },
]
export const RECIPIENT_DETAILS_OVERVIEW_PAGES = [
  { value: 0, label: 'Addresses' },
  { value: 1, label: 'Recipient Details' },
]
export const RECIPIENT_DETAILS_ACTIVITY_PAGES = [
  // { value: 0, label: 'Activity' },
  { value: 0, label: 'Transactions' },
  { value: 1, label: 'Travel Rule' },
  { value: 2, label: 'Alerts' },
]

export const PERMISSION_OPTIONS = [
  { value: 1, label: 'User' },
  { value: 1, label: 'Super User' },
  { value: 2, label: 'Admin' },
]
export const ACCOUNT_TYPE_OPTIONS = [
  { value: 1, label: 'Individual' },
  { value: 2, label: 'Entity' },
]
export const ACCOUNT_STATUS_OPTIONS = [
  { value: 0, label: 'Inactive' },
  { value: 1, label: 'Active' },
  { value: 2, label: 'On Hold' },
  { value: 3, label: 'Dormant' },
]
export const RISK_OPTIONS = [
  { value: 0, label: '-' },
  { value: 1, label: 'Low' },
  { value: 2, label: 'Medium' },
  { value: 3, label: 'High' },
]
export const YES_NO_OPTIONS = [
  { value: 1, label: 'No' },
  { value: 2, label: 'Yes' },
]
export const ACTIVITY_ENTITY_OPTIONS = [
  { value: 1, label: 'Bank' },
  { value: 2, label: 'Asset Provider' },
]
export const ACTIVITY_INDIVIDUAL_OPTIONS = [
  { value: 1, label: 'Teacher' },
  { value: 2, label: 'Software Developer' },
]
export const LINK_TYPE_OPTIONS = [
  { value: false, label: 'Account' },
  { value: true, label: 'Recipient' },
]

// Cases
export const CASE_ASSIGNEE_OPTIONS = [
  { value: 'AnalystLevel1', label: 'Analyst Level 1' },
  { value: 'AnalystLevel2', label: 'Analyst Level 2' },
  { value: 'AnalystLevel3', label: 'Analyst Level 3' },
]
export const CASE_STATUS_OPTIONS = [
  { value: 'open', label: 'Open' },
  { value: 'pending', label: 'Pending' },
  { value: 'closed', label: 'Closed' },
]
export const CASE_ASSIGNEE_OPTIONS_MAPPING = {
  AnalystLevel1: { value: 'AnalystLevel1', label: 'Analyst Level 1' },
  AnalystLevel2: { value: 'AnalystLevel2', label: 'Analyst Level 2' },
  AnalystLevel3: { value: 'AnalystLevel3', label: 'Analyst Level 3' },
}
export const CASE_STATUS_OPTIONS_MAPPING = {
  open: { value: 'open', label: 'Open' },
  pending: { value: 'pending', label: 'Pending' },
  closed: { value: 'closed', label: 'Closed' },
}

export const CASE_TYPE_OPTIONS = [
  { label: 'All' },
  { value: 1, label: 'Natural Person' },
  { value: 2, label: 'Legal Person' },
]

export const CASE_RISK_OPTIONS = [
  { value: 0, label: 'Not Available' },
  { value: 1, label: 'Low' },
  { value: 2, label: 'Medium' },
  { value: 3, label: 'High' },
]

export const ADVERSE_MEDIA_OPTIONS = [
  { value: 0, label: 'Not Available' },
  { value: 1, label: 'No' },
  { value: 2, label: 'Yes' },
]

// export const CASE_STATUS_OPTIONS = [
//   { label: 'All' },
//   { value: 0, label: 'Inactive' },
//   { value: 1, label: 'Active' },
//   { value: 2, label: 'On Hold' },
//   { value: 3, label: 'Dormant' },
// ]

export const SORT_BY_OPTIONS = [
  { value: 'Case_Since', label: 'Case Since' },
  { value: 'AddressCount', label: 'Address Count' },
  { value: 'TxCount', label: 'Transaction Count' },
]

export const POLITICALLY_EXPOSED_PERSON_OPTIONS = [
  { value: 0, label: 'Not Available' },
  { value: 1, label: 'No' },
  { value: 2, label: 'Yes' },
]

// Chains
export const CHAIN_TYPE_OPTIONS = [
  {
    value: 'bitcoin',
    label: 'Bitcoin',
    abrv: 'BTC',
    icon: Bitcoin,
    regex: {
      transaction: /^[a-fA-F0-9]{64}$/,
      address: /^[13][a-km-zA-HJ-NP-Z1-9]{25,34}$/,
    },
  },
  {
    value: 'ethereum',
    label: 'Ethereum',
    abrv: 'ETH',
    icon: Ethereum,
    regex: {
      transaction: /^0x([A-Fa-f0-9]{64})$/,
      address: /^(0x){1}[0-9a-fA-F]{40}/,
    },
  },
  {
    value: 'bitcoin-cash',
    label: 'Bitcoin Cash',
    abrv: 'BCH',
    icon: BitcoinCash,
    regex: {
      /* transaction id regex identifies any SHA256 hash */
      transaction: /[0-9a-f]{64}/,
      address: /[13][a-km-zA-HJ-NP-Z1-9]{33}/,
    },
  },
  {
    value: 'litecoin',
    label: 'Litecoin',
    abrv: 'LTC',
    icon: Litecoin,
    regex: {
      /* transaction id regex identifies any Scrypt hash */
      transaction: /^\\$s0\\$[0-9a-f]{5,6}\\$[a-zA-Z0-9/+]+[=]*\\$[a-zA-Z0-9/+]+[=]*$/,
      address: /[lm3][a-zA-Z0-9]{26,42}|[LM3][a-km-zA-HJ-NP-Z1-9]{26,33}/,
    },
  },
]
export const CHAIN_TYPE_SIMPLIFIED_OPTIONS = [
  {
    value: 'BTC',
    label: 'BTC',
  },
  {
    value: 'ETH',
    label: 'ETH',
  },
  {
    value: 'BCH',
    label: 'BCH',
  },
  {
    value: 'LTC',
    label: 'LTC',
  },
  {
    value: 'BSV',
    label: 'BSV',
  },
  {
    value: 'DOGE',
    label: 'DOGE',
  },
  {
    value: 'ZEC',
    label: 'ZEC',
  },
  {
    value: 'XRP',
    label: 'XRP',
  },
  {
    value: 'XLM',
    label: 'XLM',
  },
  {
    value: 'XDC',
    label: 'XDC',
  },
  {
    value: 'SOL',
    label: 'SOL',
  },
  {
    value: 'ALGO',
    label: 'ALGO',
  },
  {
    value: 'MATIC',
    label: 'MATIC',
  },
  {
    value: 'DOT',
    label: 'DOT',
  },
  {
    value: 'XTZ',
    label: 'XTZ',
  },
  {
    value: 'NEAR',
    label: 'NEAR',
  },
]
export const SUPPORTED_CHAINS = {
  bitcoin: 'bitcoin',
  ethereum: 'ethereum',
  litecoin: 'litecoin',
  'bitcoin-cash': 'bitcoin-cash',
  'bitcoin-sv': 'bitcoin-sv',
  doge: 'doge',
  zcash: 'zcash',
  ripple: 'ripple',
  stellar: 'stellar',
  xdc: 'xdc',
  solana: 'solana',
  algorand: 'algorand',
  polygon: 'polygon',
  polkadot: 'polkadot',
  tezos: 'tezos',
  near: 'near',
}
export const SUPPORTED_CHAIN_SYMBOL_MAPPING = {
  BTC: 'bitcoin',
  ETC: 'ethereum',
  LTC: 'litecoin',
  BCH: 'bitcoin-cash',
  BSV: 'bitcoin-sv',
  DOGE: 'doge',
  ZEC: 'zcash',
  XRP: 'ripple',
  XLM: 'stellar',
  XDC: 'xdc',
  SOL: 'solana',
  ALGO: 'algorand',
  MATIC: 'polygon',
  DOT: 'polkadot',
  XTZ: 'tezos',
  NEAR: 'near',
}

//
export const MODULE_ID_MAPPING = {
  address: 1,
  transaction: 2,
  directory: 3,
}

export const REPORT_DATA_TYPE_OPTIONS = [
  { value: 'scores', label: 'Risk Scores' },
  { value: 'financials', label: 'Financial Details' },
  { value: 'link_id', label: 'Associated Account' },
  // { value: 'business_notes', label: 'Notes' },
  // { value: 'transfer_count', label: 'Travel Rule Transfers' },
  // { value: 'alerts', label: 'Alerts' },
]
export const REPORT_TRANSACTION_DATA_TYPE_OPTIONS = [
  { value: 'inputs', label: 'Input Events' },
  { value: 'outputs', label: 'Output Events' },
]

export const REPORT_DATE_RANGE_OPTIONS = [
  { value: 'last_month', label: 'Last month' },
  { value: 'last_6_months', label: 'Last 6 months' },
  { value: 'last_12_months', label: 'Last 12 months' },
]
export const REPORT_FILE_TYPE_OPTIONS = [
  { value: 'csv', label: 'CSV' },
  { value: 'pdf', label: 'PDF' },
]

export const ADDRESS_CHAIN_OPTIONS_MAPPING = {
  bitcoin: { disabled: false, value: 'bitcoin', label: 'bitcoin' },
  ethereum: { disabled: false, value: 'ethereum', label: 'ethereum' },
  litecoin: { disabled: false, value: 'litecoin', label: 'litecoin' },
  bitcoin_cash: { disabled: true, value: 'bitcoin-cash', label: 'bitcoin-cash' },
  bitcoin_sv: { disabled: true, value: 'bitcoin-sv', label: 'bitcoin-sv' },
  doge: { disabled: true, value: 'doge', label: 'doge' },
  dash: { disabled: true, value: 'dash', label: 'dash' },
  zcash: { disabled: true, value: 'zcash', label: 'zcash' },
  groestlcoin: { disabled: true, value: 'groestlcoin', label: 'groestlcoin' },
  cardano: { disabled: true, value: 'cardano', label: 'cardano' },
  solana: { disabled: true, value: 'solana', label: 'solana' },
  eos: { disabled: true, value: 'eos', label: 'eos' },
  ethereum_classic: { disabled: true, value: 'ethereum-classic', label: 'ethereum-classic' },
  ripple: { disabled: true, value: 'ripple', label: 'ripple' },
  xdc: { disabled: true, value: 'xdc', label: 'xdc' },
  stellar: { disabled: true, value: 'stellar', label: 'stellar' },
  algorand: { disabled: true, value: 'algorand', label: 'algorand' },
  tron: { disabled: true, value: 'tron', label: 'tron' },
  polygon: { disabled: true, value: 'polygon', label: 'polygon' },
  syscoin: { disabled: true, value: 'syscoin', label: 'syscoin' },
  xinfin: { disabled: true, value: 'xinfin', label: 'xinfin' },
}

export const IP_RISK_PARTNER_OPTIONS = [
  { value: '0183c0fe-3f2b-7d4f-8a02-941b8a4f3442', label: 'RDBIG' },
  { value: '0183c0fe-3f3a-76f7-9cf4-af0b628d068c', label: 'RDCFI' },
  { value: '018379b8-a215-71bd-a8e8-001e812812c1', label: 'RDCSL' },
  { value: '0183c15e-8d17-7c8d-ae5c-c6a645d8fd7a', label: 'RDCTR' },
]

export const IP_FINANCIAL_PARTNER_OPTIONS = [
  { value: '01836485-906e-7d3f-a6aa-c6134d6384b8', label: 'FDBLC' },
  { value: '0183c101-f0e6-7a1e-84d1-ecdddd045437', label: 'Crypto Compare' },
]
export const IP_RISK_PARTNER_OPTIONS_MAPPING = {
  '018379b8-a215-71bd-a8e8-001e812812c1': 'RDCSL',
  '0183c0fe-3f2b-7d4f-8a02-941b8a4f3442': 'RDBIG',
  '0183c15e-8d17-7c8d-ae5c-c6a645d8fd7a': 'RDCTR',
  '0183c0fe-3f3a-76f7-9cf4-af0b628d068c': 'RDCFI',
}

export const ADDRESS_REGEX_OPTIONS_MAPPING = {
  bitcoin: '^[13][a-km-zA-HJ-NP-Z1-9]{25,34}$',
  ethereum: '/(\b0x[a-f0-9]{40}\b)/g',
  litecoin: '/^L[a-zA-Z0-9]{26,33}$/',
}
export const ADDRESS_REGEX_OPTIONS = [
  { chain: 'bitcoin', regex: '^[13][a-km-zA-HJ-NP-Z1-9]{25,34}$' },
  { chain: 'ethereum', regex: '/(\b0x[a-f0-9]{40}\b)/g' },
  { chain: 'litecoin', regex: '/^L[a-zA-Z0-9]{26,33}$/' },
]

export const ADDRESS_CHAIN_OPTIONS = [
  { disabled: false, value: 'bitcoin', label: 'bitcoin' },
  { disabled: false, value: 'ethereum', label: 'ethereum' },
  { disabled: false, value: 'litecoin', label: 'litecoin' },
  { disabled: false, value: 'bitcoin-cash', label: 'bitcoin-cash' },
  { disabled: false, value: 'doge', label: 'doge' },
  { disabled: false, value: 'solana', label: 'solana' },
  { disabled: false, value: 'ripple', label: 'ripple' },
  { disabled: false, value: 'xdc', label: 'xdc' },
  { disabled: false, value: 'stellar', label: 'stellar' },
  { disabled: false, value: 'algorand', label: 'algorand' },
  { disabled: false, value: 'polygon', label: 'polygon' },
  { disabled: false, value: 'polkadot', label: 'polkadot' },
  { disabled: false, value: 'tezos', label: 'tezos' },
  { disabled: false, value: 'near', label: 'near' },
  { disabled: false, value: 'xinfin', label: 'xinfin' },
]
export const TRANSACTION_CHAIN_OPTIONS = [
  { disabled: false, value: 'bitcoin', label: 'bitcoin' },
  { disabled: false, value: 'ethereum', label: 'ethereum' },
  { disabled: false, value: 'litecoin', label: 'litecoin' },
  { disabled: false, value: 'bitcoin-cash', label: 'bitcoin-cash' },
  { disabled: false, value: 'doge', label: 'doge' },
  { disabled: false, value: 'solana', label: 'solana' },
  { disabled: false, value: 'ripple', label: 'ripple' },
  { disabled: false, value: 'xdc', label: 'xdc' },
  { disabled: false, value: 'stellar', label: 'stellar' },
  { disabled: true, value: 'algorand', label: 'algorand' },
  { disabled: true, value: 'polygon', label: 'polygon' },
  { disabled: true, value: 'polkadot', label: 'polkadot' },
  { disabled: true, value: 'tezos', label: 'tezos' },
  { disabled: true, value: 'near', label: 'near' },
  { disabled: true, value: 'xinfin', label: 'xinfin' },
]
export const BIG_SUPPORTED_CHAIN_OPTIONS = {
  bitcoin: true,
  ethereum: true,
  litecoin: true,
  'bitcoin-cash': true,
  'bitcoin-sv': true,
  doge: true,
  zcash: true,
  ripple: true,
  stellar: true,
  xdc: true,
}
export const COINFIRM_SUPPORTED_CHAIN_OPTIONS = {
  bitcoin: true,
  ethereum: true,
  litecoin: true,
  'bitcoin-cash': true,
  'bitcoin-sv': true,
  doge: true,
  zcash: true,
  solana: true,
  ripple: true,
  stellar: true,
  algorand: true,
  polygon: true,
  polkadot: true,
  tezos: true,
  near: true,
}

export const INTEGRATION_PARTNERS_ID = {
  bitcoin: '0183c0fe-3f2b-7d4f-8a02-941b8a4f3442',
  ethereum: '0183c0fe-3f2b-7d4f-8a02-941b8a4f3442',
  litecoin: '0183c0fe-3f2b-7d4f-8a02-941b8a4f3442',
  'bitcoin-cash': '0183c0fe-3f2b-7d4f-8a02-941b8a4f3442',
  'bitcoin-sv': '0183c0fe-3f2b-7d4f-8a02-941b8a4f3442',
  doge: '0183c0fe-3f2b-7d4f-8a02-941b8a4f3442',
  zcash: '0183c0fe-3f2b-7d4f-8a02-941b8a4f3442',
  ripple: '0183c0fe-3f2b-7d4f-8a02-941b8a4f3442',
  stellar: '0183c0fe-3f2b-7d4f-8a02-941b8a4f3442',
  //
  solana: '0183c0fe-3f3a-76f7-9cf4-af0b628d068c',
  algorand: '0183c0fe-3f3a-76f7-9cf4-af0b628d068c',
  polygon: '0183c0fe-3f3a-76f7-9cf4-af0b628d068c',
  polkadot: '0183c0fe-3f3a-76f7-9cf4-af0b628d068c',
  tezos: '0183c0fe-3f3a-76f7-9cf4-af0b628d068c',
  near: '0183c0fe-3f3a-76f7-9cf4-af0b628d068c',
}

export const SUPPORTED_CHAIN_OPTIONS = {
  RDBIG: BIG_SUPPORTED_CHAIN_OPTIONS,
  RDCFI: COINFIRM_SUPPORTED_CHAIN_OPTIONS,
}

export const TIMEZONES = [
  {
    zoneName: 'Pacific/Pago_Pago',
    value: 'GMT-11',
    label: '(GMT-11:00) Pacific/Pago_Pago',
  },
  {
    zoneName: 'Pacific/Midway',
    value: 'GMT-11',
    label: '(GMT-11:00) Pacific/Midway',
  },
  {
    zoneName: 'Pacific/Honolulu',
    value: 'GMT-10',
    label: '(GMT-10:00) Pacific/Honolulu',
  },
  {
    zoneName: 'Pacific/Rarotonga',
    value: 'GMT-10',
    label: '(GMT-10:00) Pacific/Rarotonga',
  },
  {
    zoneName: 'Pacific/Tahiti',
    value: 'GMT-10',
    label: '(GMT-10:00) Pacific/Tahiti',
  },
  {
    zoneName: 'Pacific/Marquesas',
    value: 'GMT-9',
    label: '(GMT-09:30) Pacific/Marquesas',
  },
  {
    zoneName: 'America/Anchorage',
    value: 'GMT-8',
    label: '(GMT-08:00) America/Anchorage',
  },
  {
    zoneName: 'America/Los_Angeles',
    value: 'GMT-7',
    label: '(GMT-07:00) America/Los_Angeles',
  },
  {
    zoneName: 'America/Denver',
    value: 'GMT-6',
    label: '(GMT-06:00) America/Denver',
  },
  {
    zoneName: 'America/Chicago',
    value: 'GMT-5',
    label: '(GMT-05:00) America/Chicago',
  },
  {
    zoneName: 'America/New_York',
    value: 'GMT-4',
    label: '(GMT-04:00) America/New_York',
  },
  {
    zoneName: 'America/Caracas',
    value: 'GMT-4',
    label: '(GMT-04:00) America/Caracas',
  },
  {
    zoneName: 'America/Sao_Paulo',
    value: 'GMT-3',
    label: '(GMT-03:00) America/Sao_Paulo',
  },
  {
    zoneName: 'America/Argentina/Buenos_Aires',
    value: 'GMT-3',
    label: '(GMT-03:00) America/Argentina/Buenos_Aires',
  },
  {
    zoneName: 'Atlantic/South_Georgia',
    value: 'GMT-2',
    label: '(GMT-02:00) Atlantic/South_Georgia',
  },
  {
    zoneName: 'Atlantic/Azores',
    value: 'GMT-1',
    label: '(GMT-01:00) Atlantic/Azores',
  },
  {
    zoneName: 'Europe/London',
    value: 'GMT+0',
    label: '(GMT+00:00) Europe/London',
  },
  {
    zoneName: 'Europe/Paris',
    value: 'GMT+1',
    label: '(GMT+01:00) Europe/Paris',
  },
  {
    zoneName: 'Europe/Athens',
    value: 'GMT+2',
    label: '(GMT+02:00) Europe/Athens',
  },
  {
    zoneName: 'Europe/Moscow',
    value: 'GMT+3',
    label: '(GMT+03:00) Europe/Moscow',
  },
  {
    zoneName: 'Asia/Dubai',
    value: 'GMT+4',
    label: '(GMT+04:00) Asia/Dubai',
  },
  {
    zoneName: 'Asia/Kolkata',
    value: 'GMT+5',
    label: '(GMT+05:30) Asia/Kolkata',
  },
  {
    zoneName: 'Asia/Kathmandu',
    value: 'GMT+5',
    label: '(GMT+05:45) Asia/Kathmandu',
  },
  {
    zoneName: 'Asia/Dhaka',
    value: 'GMT+6',
    label: '(GMT+06:00) Asia/Dhaka',
  },
  {
    zoneName: 'Asia/Bangkok',
    value: 'GMT+7',
    label: '(GMT+07:00) Asia/Bangkok',
  },
  {
    zoneName: 'Asia/Hong_Kong',
    value: 'GMT+8',
    label: '(GMT+08:00) Asia/Hong_Kong',
  },
  {
    zoneName: 'Asia/Tokyo',
    value: 'GMT+9',
    label: '(GMT+09:00) Asia/Tokyo',
  },
  {
    zoneName: 'Australia/Sydney',
    value: 'GMT+10',
    label: '(GMT+10:00) Australia/Sydney',
  },
  {
    zoneName: 'Pacific/Noumea',
    value: 'GMT+11',
    label: '(GMT+11:00) Pacific/Noumea',
  },
  {
    zoneName: 'Pacific/Auckland',
    value: 'GMT+12',
    label: '(GMT+12:00) Pacific/Auckland',
  },
]
// Travel Rule
export const TRAVELRULE_METHODS = [
  {
    value: 'flow',
    label: 'Flow',
    disabled: false,
  },
  {
    value: 'trisa',
    label: 'Trisa',
    disabled: true,
  },
  {
    value: 'trp',
    label: 'TRP',
    disabled: true,
  },
  {
    value: 'gtr',
    label: 'GTR',
    disabled: true,
  },
]
// Directory
export const NATIONAL_ID_TYPE_OPTIONS = [
  { value: 'ARNU', label: 'ARNU' },
  { value: 'CCPT', label: 'CCPT' },
  { value: 'RAID', label: 'RAID' },
  { value: 'DRLC', label: 'DRLC' },
  { value: 'FIIN', label: 'FIIN' },
  { value: 'TXID', label: 'TXID' },
  { value: 'SOCS', label: 'SOCS' },
  { value: 'IDCD', label: 'IDCD' },
  { value: 'LEIX', label: 'LEIX' },
  { value: 'MISC', label: 'MISC' },
]

// Profile
export const ROLE_OPTIONS = [
  { label: 'Manager', value: 1 },
  { label: 'Basic User', value: 2 },
]
