import axios from 'axios'
import { authAxios } from 'common/utils/axios'

import { ADMIN_ENDPOINTS, LOCAL_URL } from 'common/constants/apiUrls'

const accessToken = JSON.parse(localStorage.getItem('userCredentials'))?.access_token

export const newAuthAxios = axios.create({
  baseURL: LOCAL_URL,
  headers: {
    Authorization: `Bearer ${accessToken}`,
  },
})

export const NotifyVasp = async ({ vasp_id }) => {
  const response = await authAxios.post(`${ADMIN_ENDPOINTS.NOTIFY_VASP}/${vasp_id}`, {
    vasp_id,
  })

  const { data } = response

  return data
}
